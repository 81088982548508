.container {
    display: flex;
    flex-direction: column;
    margin: 60px 0;
}

.left {
    width: 50%;
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: block;
    margin-bottom: 16px;
}

.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 20px;
}

.right {
    display: flex;
    flex-wrap: wrap;
}

.block {
    width: 400px;
    background: #FFFFFF;
    border-radius: 16px;
    margin-right: 40px;
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 16px 24px;
}

.block:nth-child(3n + 3) {
    margin-right: 0;
}
.iconContainer {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
}
.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blockText {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    display: block;
    margin-bottom: 5px;
}

.nameCar {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: block;
}

.options {
    margin-top: 20px;
}

.option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.option span {
    display: inline-block;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

@media screen and (max-width: 1450px) {
    .left {
        width: 100%;
    }
    .right {
        justify-content: space-between;
    }
    .block {
        margin-left: 0;
    }
}

@media screen and (max-width: 1250px) {
    .container {
        flex-direction: column;
    }

    .left {
        width: auto;
    }

    .right {
        justify-content: space-between;
    }

    .block {
        width: 224px;
        margin-left: 0;
    }
}

@media screen and (max-width: 1300px) {
    .right {
        justify-content: center;
        flex-direction: column;
    }
    .block {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .blockText, .nameCar {
        display: block;
        margin: 0 0 0 0;
        font-weight: 500;
    }
    .blockText {
        font-size: 16px;
        line-height: 24px;
    }
    .nameCar {
        font-size: 24px;
        line-height: 32px;
    }
    .icon {
        margin: 0 0 0 24px;
    }
}
.transferDetails {
    width: 100%;
    background: white;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
}

.left {
    width: 380px;
    min-width: 380px;
    box-sizing: border-box;
}
.editButton {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4230FF;
    cursor: pointer;
    margin-left: 10px;
    display: block;
}

.content {
    margin-bottom: 12px;
    width: 200px;
}

.greyText {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
}

.blackText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.blackText span {
    color: #8697A5;
}

.containerDate {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.smallContent {
    width: 150px;
}

.car {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    position: relative;
    height: 120px;
    margin-bottom: 16px;
    display: flex;
    margin-right: 10px;
}

.carTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #8697A5;
}

.carTextBlock {
    margin-left: 8px;
    margin-top: 8px;
}

.carText {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    width: 100px;
}

.price {
    display: flex;
    align-items: center;
}

.count {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: #4230FF;
    margin-right: 12px;
}

.priceText {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.right {
    position: relative;
    width: 360px;
    height: 380px;
}


.personaldetails {
    width: 100%;
    background: white;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
}

.personBlock {
    display: flex;
    justify-content: space-between;
}

.wrpaerPerson {
    margin-right: 0;
}

.widthPay {
    width: 136px;
    margin-right: 70px;
}

.bigPay {
    width: 300px;
    margin-right: 0;
}

.carTransferImg {
    width: 200px;
    height: 100px;
    margin-left: -40px;
}

.carTransferImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1250px) {
    .left {
        width: 340px;
        min-width: 340px;
    }
    .right {
        width: 320px;
        height: 380px;
    }
}

@media screen and (max-width: 1300px) {
    .transferDetails {
        flex-direction: column;
        width: 100%;
    }
    .left {
        width: 100%;
        min-width: 100%;
    }
    .right {
        width: 100%;
        height: 288px;
    }

    .car img {
        width: 170px;
        top: 10px
    }

    .carTitle {
    }

    .carText {
        width: 110px;
    }
}

@media screen and (max-width: 730px) {
    .car {
        position: relative;
    }
    .carTransferImg {
        position: absolute;
        right: 0;
        width: auto;
        height: 60px;
        bottom: 20px;
    }

    .personBlock {
        flex-direction: column;
    }

    .price {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 16px;
    }

    .count {
        font-size: 18px;
    }
}
.container {
    background: #F5F5F5;
    padding: 32px 0;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.name {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    display: block;
    margin-bottom: 8px;
    margin-top: 10px;
}

.info {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
    display: block;
    width: 180px;
}

.list {
    display: flex;
    width: 500px;
    justify-content: flex-start;
}

.block {
    display: flex;
    flex-direction: column;
    margin-right: 150px;
}

.link {
    display: block;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4230FF;
    margin-top: 16px;
    transition: .3s;
}

.link:hover {
    opacity: .8;
}

.right {
    display: flex;
}

.icon {
    margin-left: 36px;
    transition: .3s;
}

.icon:hover {
    opacity: .8;
}

@media screen and (max-width: 1450px) {
}

@media screen and (max-width: 1300px) {
    .list {
        width: 30%;
    }
}

@media screen and (max-width: 730px) {
    .list, .block, .footer, .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .block {
        margin-right: 0;
    }
    .list {
        margin-bottom: 20px;
        width: 100%;
    }
    .right {
        width: 100%;
        justify-content: space-between;
    }
    .icon {
        margin: 0;
    }
}
.container {
    display: flex;
    justify-content: space-between;
    margin: 60px 0;
}

.left {
    width: 50%;
}

.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    display: block;
    margin-bottom: 16px;
}

.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 20px;
}

.right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.block {
    width: 240px;
    height: 240px;
    margin-left: 40px;
    border: 1px solid #D4DCE3;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
}

.block:first-child, .block:nth-child(4) {
    margin-left: 0;
}

.icon {
    margin: 24px 0 0 24px;
    width: 48px;
    height: 48px;
}

.blockText {
    display: block;
    margin: 100px 0 0 24px;
    width: 140px;
}

@media screen and (max-width: 1450px) {
    .left {
        width: 100%;
    }
}

@media screen and (max-width: 1250px) {
    .container {
        flex-direction: column;
    }

    .left {
        width: auto;
        min-width: 0;
    }

    .right {
        justify-content: space-between;
    }

    .block {
        width: 224px;
        margin-left: 0;
    }
}

@media screen and (max-width: 1300px) {
    .right {
        justify-content: center;
        flex-direction: column;
    }
    .block {
        width: 100%;
        margin-bottom: 16px;
        height: 96px;
        display: flex;
        align-items: center;
    }

    .blockText {
        display: block;
        margin: 0 0 0 24px;
        width: 140px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .icon {
        margin: 0 0 0 24px;
    }
}
.wrapper {
    width: 1280px;
    margin: 0 auto;
}

@media screen and (max-width: 1450px) {
    .wrapper {
        width: 1280px;
    }
}

@media screen and (max-width: 1300px) {
    .wrapper {
        width: 720px;
    }
}

@media screen and (max-width: 730px) {
    .wrapper {
        width: 288px;
    }
}
.container {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 140px;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    color: #4230FF;
    margin-bottom: 45px;
    display: block;
    width: 410px;
}

.list {
    margin-bottom: 50px;
}

.listContent {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.listContent img {
    margin-right: 24px;
}

.leftFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4230FF;
    margin-right: 48px;
}

.sum {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    display: block;
}

.buttonsBlock {
    display: flex;
}

.buttonMargin {
    margin-right: 25px;
}

.right {
    width: 580px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gradient {
    position: absolute;
    left: 0;
    bottom: 0;
}

.car {
    position: absolute;
    right: 0;
    bottom: 100px;
}

.name {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-left: 50px;
}

.text {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 0 50px;
    width: 250px;
    padding-bottom: 18px;
}

.mobileView {
    display: none;
}

@media screen and (max-width: 1450px) {
    .title {
        width: auto;
        font-size: 36px;
        line-height: 48px;
    }
    .right {
        width: 420px;
    }
    .car {
        width: 600px;
    }
    .gradient {
        width: 380px;
    }
    .sum {
        font-size: 24px;
    }
}

@media screen and (max-width: 1300px) {
    .title {
        width: auto;
        font-size: 24px;
        line-height: 36px;
    }
    .right {
        min-width: 340px;
        width: 340px;
        justify-content: flex-start;
    }
    .car {
        width: 480px;
        top: 0;
    }
    .gradient {
        width: 320px;
        top: 0;
        bottom: auto;
    }
    .sum {
        font-size: 24px;
    }
    .name {
        padding-top: 270px;
        margin-left: 24px;
        font-size: 14px;
        line-height: 20px;
    }
    .text {
        margin-left: 24px;
        font-size: 12px;
        line-height: 16px;
    }
    .listContent {
        font-size: 14px;
    }
    .leftFooter {
        flex-direction: column;
        align-items: flex-start;
    }
    .list {
        margin-bottom: 20px;
    }
    .price {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 730px) {
    .mobileView {
        margin-top: 20px;
        display: block;
    }
    .container {
        flex-direction: column;
        padding: 0;
    }
    .mobile {
        display: none;
    }
    .right {
        width: 288px;
        position: relative;
        min-width: 288px;
        margin-bottom: 20px;
    }
    .gradient {
        width: 288px;
        height: 320px;
        top: 0;
    }
    .car {
        width: 360px;
    }
    .name {
        padding-top: 210px;
    }

    .leftFooter {
        align-items: center;
    }
    .price {
        margin-right: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        text-align: center;
    }
    .buttonsBlock {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .buttonMargin {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
.container {
    position: absolute;
    top: 150px;
    margin: auto;
    left: 0;
    right: 0;
    width: 1280px;
    display: flex;
    justify-content: space-between;
}

.block {
    background: white;
    width: 480px;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px 0 24px;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
}

.title {
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    color: #FFFFFF;
    width: 480px;
    margin-bottom: 20px;
}

.titleDescription {
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
}

.wrapper {
    width: 416px;
    margin: 0 auto;
}

.tabs {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8697A5;
    cursor: pointer;
    width: 200px;
    text-align: center;
    padding-bottom: 10px;
    transition: .3s;
    border-bottom: 3px solid transparent;
}
.tab:hover {
    opacity: .8;
}

.activeTab {
    color: #4230FF;
    border-bottom: 3px solid #4230FF;
}
.divider {
    width: 100%;
    height: 1px;
    background: #D4DCE3;
    margin-bottom: 20px;
}

.form {
    margin-bottom: 16px;
}

.inputForm {
    width: 100%;
    height: 56px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    padding-left: 12px;
    border: 1px solid #D4DCE3;
    border-radius: 8px;
    margin-bottom: 16px;
}

.firstInput {
    border: 1px solid #D4DCE3;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}
.textareaWrapper {
    margin-bottom: 16px;
}

.secondInput {
    border: 1px solid #D4DCE3;
    border-top: none;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
}
.wrapperField {
    border: 1px solid #D4DCE3;
    border-radius: 8px;
}
.formDate {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4DCE3;
    border-radius: 8px;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.date, .time {
    font-weight: 500;
    height: 56px;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 8px;
}

.formDateError {
    border-color: red;
}

.time {
    position: relative;
    width: 170px;
}

.date {
    min-width: 228px;
}

.dividerTime {
    min-width: 1px;
    height: 40px;
    background: #D4DCE3;
}

.formCheckbox {
    margin-bottom: 16px;
}

.passengers {
    margin-bottom: 20px;
}

.currencyBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.currency {
    width: 212px;
    border-radius: 8px;
}

.button {
    width: 100%;
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 56px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    transition: .3s;
    margin-top: 16px;
}

.buttonSubmit {
    margin-left: 0;
    width: 100%;
}

.button:hover {
    opacity: .8;
}

.disabledButton {
    opacity: 0.3;
}

.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.textModal {
    margin-top: 20px;
}

.disabledButton:hover {
    opacity: 0.3;
}

.individualInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 16px;
}

.smallInput {
    width: 196px;
}

.lagguage {
    position: relative;
}

.lagguageText {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
}

.lagguageButtons {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 68px;
    height: 24px;
    display: flex;
    justify-content: space-between;
}

.lagguageButton {
    width: 32px;
    height: 24px;
    background: #F5F5F5;
    border: 1px solid #D4DCE3;
    box-sizing: border-box;
    border-radius: 4px 2px 2px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    color: #8697A5;
    font-size: 24px;
}

.lagguageButton:hover {
    opacity: .6;
}

.lagguageButtonPlus {
    border-radius: 2px 4px 4px 2px;
}

.textarea {
    width: 100%;
    height: 248px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    padding: 12px;
    border: 1px solid #D4DCE3;
    border-radius: 8px;
    resize: none;
    margin-bottom: 24px;
}

.titleMobile {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: none;
}

.buttonsPrice {
    width: 100%;
    margin: 0 auto 20px;
    display: none;
    justify-content: space-between;
}

.buttonPrice {
    width: 100%;
    border: 0;
    background: transparent;
    outline: none;
    border-top: 1px solid #D4DCE3;
    border-bottom: 1px solid #D4DCE3;
    border-left: 1px solid #D4DCE3;
    height: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #D4DCE3;
}

.buttonPrice:last-child {
    border-right: 1px solid #D4DCE3;
    border-radius: 0 16px 16px 0;
}

.buttonPrice:first-child {
    border-right: 1px solid #D4DCE3;
    border-radius: 16px 0 0 16px;
}

.buttonPriceActive {
    background: #D4DCE3;
    color: #000000;
}
.clearContainer {
    text-align: center;
}
.clear {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    transition: .3s;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8697A5;
    display: inline-block;
}

.clear:hover {
    opacity: .8;
}

@media screen and (max-width: 1450px) {
    .container {
        width: 1280px;
    }
}

@media screen and (max-width: 1300px) {
    .container {
        width: 720px;
    }
    .wrapper {
        width: 300px;
    }

    .block {
        width: 340px;
    }

    .title {
        width: 300px;
        font-size: 32px;
    }
    .time {
        width: 50%;
    }

    .date {
        min-width: 50%;
    }
    .smallInput {
        margin-right: 10px;
    }
    .smallInput:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 730px) {
    .container, .block {
        width: 100%;
        position: relative;
        flex-direction: column;
    }

    .title {
        display: none;
    }

    .wrapper {
        width: 288px;
    }

    .time {
        width: 50%;
    }

    .date {
        min-width: 50%;
    }

    .currencyBlock, .individualInfo {
        flex-direction: column;
    }

    .currency {
        display: none;
    }

    .button, .smallInput {
        width: 100%;
    }
    .smallInput {
        margin-bottom: 16px;
    }

    .smallInput:last-child {
        margin-bottom: 0;
    }
    .buttonsPrice {
        display: flex;
    }
    .container {
        top: -110px;
    }
    .block {
        box-shadow: none;
    }
    .titleMobile {
        display: block;
    }
}
.container {
    background: #F5F5F5;
}
.body {
    min-height: calc(100vh - 310px);
    display: flex;
    justify-content: space-between;
}

.left {
    margin-top: 48px;
    margin-bottom: 100px;
}

.infos {
    margin-bottom: 48px;
}

.info {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: black;
    margin-bottom: 16px;
    text-decoration: none;
}
.info:last-child {
    margin-bottom: 0;
}
.info img, .info span {
    margin-right: 16px;
}

.info a {
    color: #4230FF;
}
.title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}

.links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 450px;
}

.link {
    width: 200px;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 16px;
}

.link img {
    margin-right: 16px;
}

.right {
    width: 624px;
    height: 464px;
    background: white;
    padding: 8px;
    border-radius: 16px;
    margin-top: -40px;
}

.wrapperMap {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
}

.phone {
    text-decoration: none;
    color: black !important;
    margin-right: 10px;
}

@media screen and (max-width: 1450px) {
    .right {
        width: 500px;
        height: 350px;
        background: white;
        padding: 8px;
        border-radius: 16px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 1300px) {
    .body {
        flex-direction: column;
    }
    .links {
        flex-direction: column;
        width: 100%;
    }
    .info {
        font-size: 14px;
    }

    .right {
        width: 100%;
        height: 300px;
    }

    .link {
        font-size: 14px;
    }
}
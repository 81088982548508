.container {
    cursor: pointer;
    position: relative;
}

.wrapperField {
    border: 1px solid #D4DCE3;
    border-radius: 8px;
}

.passengers {
}

.menu {
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #D4DCE3;
    box-sizing: border-box;
    border-radius: 8px;
    top: 70px;
    left: 0px;
    box-sizing: border-box;
    z-index: 90;
    padding: 16px;
    min-width: 270px;
    cursor: default;
}

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.divider {
    background: #D4DCE3;
    width: 100%;
    height: 1px;
    margin: 10px 0;
}

.item:last-child {
    margin-bottom: 0;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.counterBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lagguageText {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
    width: 250px;
}


.button {
    background: #F5F5F5;
    border: 1px solid #D4DCE3;
    box-sizing: border-box;
    border-radius: 4px 2px 2px 4px;
    width: 32px;
    height: 24px;
    box-sizing: border-box;
    color: #8697A5;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s;
}

.button:hover {
    opacity: .6;
}

.value {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 40px;
    text-align: center;
}

@media screen and (max-width: 730px) {
    .menu {
        position: static;
        margin-top: 16px;
        min-width: 270px;
        width: 100%;
    }
}
.container {
    position: relative;
}
.wrapper {
    width: 1280px;
    margin: 0 auto;
}
.slide {
    height: 720px;
    overflow: hidden;
    width: 1280px;
    border-radius: 16px;
    outline: none;
}
.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pag {
    width: 80px;
    height: 4px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.4;
    border-radius: 3px;
}
.pagActive {
    opacity: 1;
}

.sizePag {
    width: 80px !important;
}

.leftArrow {
    display: block;
    width: 40px;
    height: 40px;
    left: 20px;
}

.rightArrow {
    display: block;
    width: 40px;
    height: 40px;
    zIndex: 99;
    right: 20px;
}

@media screen and (max-width: 1450px) {
    .slide {
        height: 540px;
        width: 1150px;
    }
    .wrapper {
        width: 1150px;
    }
}

@media screen and (max-width: 1250px) {
    .slide {
        height: 540px;
        width: 720px;
    }
    .wrapper {
        width: 720px;
    }
}

@media screen and (max-width: 1300px) {
    .slide {
        height: 240px;
        width: 100%;
    }
    .wrapper {
        width: 100%;
    }
    .pag {
        width: 30px;
    }
    .sizePag {
        width: 30px !important;
    }
}
.wrapper {
    width: 1280px;
    margin: 0 auto;

}
.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
}

.content {
    display: flex;
    flex-wrap: wrap;
}
.container {
    background: #F5F5F5;
    padding-top: 50px;
    padding-bottom: 80px;
}

.block {
    background: #FFFFFF;
    box-shadow: 0 8px 16px rgba(106, 103, 135, 0.16);
    border-radius: 8px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 302px;
    margin-right: 24px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block:nth-child(4n + 4) {
    margin-right: 0;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.price {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #4230FF;
}

@media screen and (max-width: 1450px) {
    .wrapper {
        width: 1150px;
    }
    .block {
        width: 304px;
    }
    .block:nth-child(4n + 4) {
        margin-right: 24px;
    }

    .block:nth-child(3n + 3) {
        margin-right: 0;
    }
}

@media screen and (max-width: 1300px) {
    .wrapper {
        width: 720px;
    }
    .block {
        margin-right: 0;
        width: 348px;
    }
    .block:nth-child(4n + 4) {
        margin-right: 0;
    }

    .block:nth-child(3n + 3) {
        margin-right: 0;
    }
    .content {
        justify-content: space-between;
    }
}

@media screen and (max-width: 730px) {
    .wrapper {
        width: 288px;
    }
    .block {
        width: 100%;
    }
}
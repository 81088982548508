.header {
    color: white;
    width: 100%;
    padding-top: 16px;
    background: #4230FF;
}
.wrapper {
    width: 1360px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 72px;
}

.headerTextWrapper {
    padding-bottom: 16px;
}

.list {
    display: flex;
    list-style: none;
}

.link {
    color: white;
    margin-left: 15px;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: 0.3s;
    display: flex;
    padding: 4px 10px;
    border-radius: 4px;
    align-items: center;
}

.link:hover {
    opacity: .8;
    background: rgba(225, 225, 225, 0.16);
}
.linkPhone {
    flex-direction: column;
    align-items: flex-start;
}

.secondText {
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
}

.activeLink {
    background: rgba(225, 225, 225, 0.16);
}

.image {
    margin-right: 3px;
}

.menu {
    display: none;
}

.titleMobile {
    display: none;
}

.menu_icon_wrapper {
    display: none;
    width: 20px;
    height: 14px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
}

.menu_icon {
    position: relative;
    width: 20px;
    height: 2px;
    background-color: #fff;
}

.menu_icon::before {
    position: absolute;
    left: 0;
    top: -5px;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #fff;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s;

}

.menu_icon::after {
    position: absolute;
    left: 0;
    top: 5px;
    content: '';
    width: 20px;
    height: 2px;
    background-color: #fff;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s;

}

.menu_icon.menu_icon_active {
    background-color: transparent;
}

.menu_icon.menu_icon_active::before {
    transform: rotate(45deg);
    top: 0;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

.menu_icon.menu_icon_active::after {
    transform: rotate(-45deg);
    top: 0;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
}

.mobilemenu {
    position: absolute;
    left: 0;
    top: -16px;
    background: #4230FF;
    height: 500px;
    width: 100%;
    z-index: 900;
}

.listMobile {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    padding-top: 80px;
    padding-left: 0;
}

.logo {
    position: relative;
    z-index: 999;
}

.linkMobile {
    color: white;
    margin-bottom: 24px;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    transition: 0.3s;
    display: block;
}

.buttons {
    width: 240px;
    margin: 0 auto 20px;
    display: flex;
    justify-content: space-between;
}

.button {
    width: 100%;
    border: 0;
    background: transparent;
    outline: none;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    height: 32px;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    white-space: nowrap;
}

.button:last-child {
    border-right: 1px solid white;
    border-radius: 0 16px 16px 0;
}

.button:first-child {
    border-right: 1px solid white;
    border-radius: 16px 0 0 16px;
}

.headerText {
    width: 1360px;
    margin: 0 auto;
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.buttonsPrices {
    width: 240px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
}

.buttonPrices {
    width: 100%;
    border: 0;
    background: transparent;
    outline: none;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
    height: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}

.buttonPrices:last-child {
    border-right: 1px solid white;
    border-radius: 0 8px 8px 0;
}

.buttonPrices:first-child {
    border-right: 1px solid white;
    border-radius: 8px 0 0 8px;
}

.buttonActive {
    background: white;
    color: #000000;
}
.iconsPhone {
    margin-left: 8px;
    margin-top: 4px;
    text-decoration: none;
}


@media screen and (max-width: 1450px) {
    .wrapper, .headerText {
        width: 1150px;
    }
}

@media screen and (max-width: 1250px) {
    .wrapper, .headerText {
        width: 720px;
    }

    .link {
        margin-left: 5px;
    }
}

@media screen and (max-width: 1300px) {
    .wrapper, .headerText {
        width: 90%;
    }
    .logo {
        width: 130px;
    }
    .list {
        display: none;
    }
    .menu {
        display: flex;
        align-items: center;
    }
    .menu_icon_wrapper {
        display: flex;
        align-items: center;
    }

    .header {
        padding-bottom: 5px;
        align-items: center;
    }

    .headerText {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }
    .iconsMessage {
        display: flex;
        align-items: center;
    }
    .iconsPhone {
        margin-left: 8px;
        margin-top: 4px;
        text-decoration: none;
    }

    .buttonsPrices {
        margin-left: 16px;
    }
    .titleMobile {
        display: block;
        margin-right: 24px;
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
        color: white;
    }
}

@media screen and (max-width: 730px) {
    .logo {
        width: 100px;
    }
    .buttonPrices {
        font-size: 10px;
        line-height: 10px;
    }
}
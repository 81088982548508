.map {
    position: absolute;
    height: 400px;
    top: 149px;
    width: 100%;
}
.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
}

.container {
    margin: -40px auto 0;
    width: 1280px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.rightContainer {
    width: 812px;
}

.block {
    background: white;
    width: 428px;
    min-width: 428px;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 20px 0 24px;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
    position: relative;
    z-index: 100;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.title img {
    margin: 0 10px;
}

.title span {
    color: #D4DCE3
}

.wrapper {
    width: 380px;
    margin: 0 auto;
}

.form {
    margin-bottom: 16px;
}

.inputForm {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D4DCE3;
    border-radius: 8px;
    cursor: pointer;
}

.firstInput {
    border-radius: 8px 8px 0 0;
}
.secondInput {
    border-top: none;
    border-radius: 0 0 8px 8px;
}

.formDate, .formDateBordered {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D4DCE3;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.smallText {
    display: block;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
}

.divider {
    width: 100%;
    height: 1px;
    background: #D4DCE3;
    margin-bottom: 20px;
}

.formDateBordered {
    border: 1px solid #D4DCE3;
    border-radius: 8px;
}

.errorTime {
    border-color: red;
}

.date, .time, .fname {
    font-weight: 500;
    height: 56px;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 8px;
}
.phoneContainer {
    margin-bottom: 16px;
}
.fname {
    width: 188px;
}

.time {
    position: relative;
    width: 170px;
}

.date {
    min-width: 200px;
}

.dividerTime {
    min-width: 1px;
    height: 40px;
    background: #D4DCE3;
}


.formCheckbox, .twoForm {
    margin-bottom: 16px;
}

.textareaWrapper {
    margin-bottom: 16px;
}

.blockInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.passengers {
    width: 200px;
}

.currency {
    width: 164px;
}

.phone, .email {
    margin-bottom: 16px;
}

.twoForm {
    display: flex;
    justify-content: space-between;
}

.formSmall {
    width: 182px;
}

.text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
    display: block;
    margin-bottom: 16px;
}

.backButton {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #4230FF;
    display: block;
    margin-bottom: 16px;
    cursor: pointer;
    margin-top: 10px;
}

.blockPayment {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.paymant {
    width: 182px;
    border: 1px solid #D4DCE3;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
    cursor: pointer;
}

.paymantTitle {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.paymantText {
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
}
.paymantActive {
    background: #4230FF;
    border: 1px solid transparent;
}

.paymantActive .paymantTitle, .paymantActive .paymantText {
    color: white;
}

.progress {
    margin-bottom: 16px;
}

.textBold {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: block;
    margin-bottom: 16px;
}

.textLight {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 16px;
}

.textLightError {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 16px;
    color: red;
}

.textLight span {
    color: #4230FF;
}
.individual {
    background: rgba(66,48,255,0.08);
    border-radius: 8px;
    padding: 8px 12px;
    box-sizing: border-box;
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.individual button {
    outline: none;
    border: none;
    background: transparent;
    color: #4230FF;
    text-decoration: underline;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    font-weight: bold;
}

.clear {
    margin-top: 10px;
    color: #4230FF;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    transition: .3s;
}

.clear:hover {
    opacity: .8;
}

.tryagain {
    text-decoration: underline;
    cursor: pointer;
}

.modalContent {
    width: 416px;
    margin: 20px auto 20px;
}

.titleModal {
    font-weight: bold;
    font-size: 24px;
    margin: 30px auto 20px;
    width: 416px;
}

.dividerModal {
    background: #D4DCE3;
    height: 1px;
    width: 100%;
}

.formError {
    border-color: red;
}

.titleGray {
    color: #8697A5;
    font-size: 20px;
}

.doneContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

.doneBlock {
    width: 50%;
    margin-bottom: 10px;
}

.greyText {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #8697A5;
}

.blackText {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.formWithSmallText {
    margin-bottom: 0;
}

.print {
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 56px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 16px;
    width: 200px;
}

@media screen and (max-width: 1450px) {
    .container {
        width: 1150px;
    }
    .block {
        width: 400px;
        min-width: 400px;
    }

    .wrapper {
        width: 344px;
    }

    .time {
        width: 50%;
    }

    .date {
        width: 50%;
    }

    .passengers {
        width: 50%;
    }

    .currency {
        width: 40%;
    }

    .rightContainer {
        width: 700px;
    }
    .fname {
        width: 50%;
    }
    .formSmall {
        width: 50%;
    }
}


@media screen and (max-width: 1300px) {
    .container {
        width: 720px;
        flex-direction: column;
    }
    .mobile_container {
        position: relative;
        top: -30px;
    }
    .block {
        width: 100%;
        min-width: 100%;
    }
    .wrapper {
        width: 90%;
    }
    .rightContainer {
        width: 720px;
        margin-top: 16px;
    }
    .paymant {
        width: 49%;
    }
}

@media screen and (max-width: 730px) {
    .container {
        width: 300px;
        flex-direction: column;
    }
    .mobile_container {
        position: relative;
        top: -30px;
    }
    .block {
        width: 100%;
        min-width: 100%;
    }
    .wrapper {
        width: 90%;
    }

    .rightContainer {
        width: 300px;
    }
    .date {
        min-width: 60%;
    }
    .twoForm {
        flex-direction: column;
    }
    .formSmall {
        width: 100%;
        margin-bottom: 16px;
    }
    .doneBlock {
        width: 100%;
    }

    .title {
        font-size: 16px;
        line-height: 16px;
    }

    .title img {
       width: 10px;
       margin: 0 8px;
    }
}
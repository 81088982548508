.conteiner {
    margin-left: 15px;
    position: relative;
    z-index: 9999;
}
.value {
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    transition: 0.3s;
    display: flex;
    padding: 4px 10px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}


.value:hover {
    opacity: .8;
    background: rgba(225, 225, 225, 0.16);
}

.listContent {
    opacity: .8;
    background: rgba(1, 1, 1, 0.4);
    margin-bottom: 5px;
}

.listContent:hover {
    opacity: .5;
}

.content {
    left: 0;
    top: 35px;
    position: absolute;
    width: 90px;
}

@media screen and (max-width: 1250px) {
    .conteiner {
        margin-left: 5px;
    }
}
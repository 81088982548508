.container {
    margin-bottom: 110px;
}
.title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 64px;
    color: #4230FF;
    display: block;
    margin-bottom: 56px;
}

.blocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.block {
    width: 360px;
    margin-bottom: 56px;
    cursor: pointer;
    transition: .3s;
    padding: 16px;
    height: 160px;
}

.titleBlock {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: block;
    margin-bottom: 16px;
}

.text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    display: block;
    margin: 0;
}

.hoverBlock {
    display: none;
}

.notification {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4230FF;
    display: block;
    margin-bottom: 20px;
}

.block:hover {
    background: rgba(66, 48, 255, 0.04);
    border-radius: 8px;
}

.block:hover .text {
    display: none;
}

.block:hover .titleBlock {
    color: #4230FF;
}

.block:hover .hoverBlock {
    display: block;
}

.button {
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 40px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: .3s;
}

.button:hover {
    opacity: .8;
}

@media screen and (max-width: 1250px) {
    .block {
        width: 320px;
    }
}

@media screen and (max-width: 1300px) {
    .block {
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
        padding-right: 0;
    }
    .title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
    }
}
.container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    margin-bottom: 110px;
}

.left {
    display: flex;
    flex-direction: column;
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 64px;
    color: #4230FF;
    display: block;
    margin-bottom: 50px;
}

.derectionName {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: block;
    margin-bottom: 16px;
}

.description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    display: block;
    margin: 0 0 30px;
    width: 360px;
}

.textTransfer {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #4230FF;
    display: block;
    margin-bottom: 16px;
}

.button {
    width: 180px;
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 48px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: .3s;
}

.button:hover {
    opacity: .8;
}

.right {
    position: relative;
    width: 840px;
    height: 480px;
    overflow: hidden;
    border-radius: 16px;
    margin-top: 90px;
    min-width: 840px;
}
.form {
    margin-bottom: 24px;
}

.firstInput {
    width: 400px;
    height: 56px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D4DCE3;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}

.secondInput {
    width: 400px;
    height: 56px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #D4DCE3;
    border-top: none;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
}

@media screen and (max-width: 1450px) {
    .right {
        width: 560px;
        height: 400px;
        min-width: 560px;
    }
    .secondInput, .firstInput {
        width: 360px;
    }
}

@media screen and (max-width: 1250px) {
    .container {
        flex-direction: column;
    }
    .description {
        width: auto;
    }
    .secondInput, .firstInput {
        width: 100%;
    }
    .right {
        width: 100%;
        height: 360px;
        min-width: 100%;
        margin-top: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .right {
        height: 280px;
    }
    .container {
        margin: 60px 0;
    }
}
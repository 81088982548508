* {
    box-sizing: border-box;
}

.inp {
    position: relative;
    margin: auto;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
}

.inp .label {
    position: absolute;
    top: -3px;
    left: 12px;
    font-size: 14px;
    color: #8697A5;
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0,0,0);
    transition: all .2s ease;
    pointer-events: none;
}

.inp .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
}

.inp input {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border: 0;
    font-family: inherit;
    padding: 12px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: transparent;
    color: black;
    transition: all .15s ease;
}

.inp input:hover {
    background: transparent;
}

.inp input:not(:placeholder-shown) + .label {
    color: #8697A5;
    transform: translate3d(0,-14px,0) scale(.75);
}

.inp input:focus {
    background: transparent;
    outline: none;
}

.inp input:focus + .label {
    color: gray;
    transform: translate3d(0,-14px,0) scale(.75)
}

.inp input:focus + .label + .focus-bg{
    transform: scaleX(1);
    transition: all .1s ease;
}
input[type="time"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none;
}

.label span {
    color: red;
}

.bordered input {
    border: 1px solid #D4DCE3;
    border-radius: 8px;
}

.wrapper {
    width: 1280px;
    margin: -40px auto 0;
    position: relative;
}
.container {
    z-index: 80;
}

.blocks {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 800px;
    margin-left: 500px;
}

.block {
    width: 320px;
    background: white;
    position: relative;
    border-radius: 16px;
    padding-top: 14px;
    margin-right: 40px;
    box-sizing: border-box;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
    margin-bottom: 24px;
    cursor: pointer;
}

.errorCar {
    background: white;
    box-shadow: 0 8px 24px rgba(106, 103, 135, 0.16);
    padding: 8px 12px;
    border-radius: 16px;
    color: #4230FF
}

.activeBlock {
    background: #4230FF;
}

.text {
    margin-left: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: block;
    padding-right: 10px;
}

.carName {
    margin-left: 24px;
    font-size: 14px;
    color: #000000;
    margin-bottom: 10px;
    display: block;
    width: 172px;
}

.price {
    margin-left: 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #4230FF;
    display: block;
}
.activeBlock .text, .activeBlock .price{
    color: white;
}

.activeBlock .text, .activeBlock .carName{
    color: white;
}
.carTop {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}
.car {
    min-width: 140px;
    height: 80px;
    width: 140px;
}

.car img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.icons, .iconBlock, .bottom {
    display: flex;
    align-items: center;
}
.bottom {
    margin-left: 500px;
}
.iconBlock {
    margin-left: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8697A5;
    cursor: pointer;
}
.iconBlock img {
    margin-right: 8px;
}

.carOptions {
    padding: 0 24px;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option {
    display: flex;
    align-items: center;
}

.option img {
    width: 16px;
}

.option span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 8px;
    display: block;
}

.activeBlock .option span {
    color: white;
}

.optionDisable {
    opacity: .5;
}

.about {
    display: flex;
    align-items: center;
    margin-left: 24px;
    margin-bottom: 16px;
}

.about span {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4230FF;
    opacity: 0.48;
    display: block;
    margin-left: 8px;
}

.aboutActive span {
    color: #FFFFFF;
    opacity: 0.48;
}

.blockModal {
    box-sizing: border-box;
    padding: 40px 24px 16px;
}

.iconContainer {
    width: 480px;
    height: 200px;
    margin-bottom: 20px;
}
.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blockText {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    display: block;
    margin-bottom: 5px;
}

.nameCar {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: block;
}

.optionsModal {
    margin-top: 20px;
}

.optionModal {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.optionModal span {
    display: inline-block;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

@media screen and (max-width: 1450px) {
    .wrapper {
        width: 1150px;
    }

    .block {
        width: 320px;
        margin-right: 0;
        margin-left: 0;
    }
    .block:first-child {
        margin-left: 0;
    }
    .blocks {
        width: 650px;
        margin-left: 440px;
    }
    .iconBlock {
        display: none;
    }
    .bottom {
        margin-left: 440px;
    }
}

@media screen and (max-width: 1250px) {
    .wrapper {
        width: 300px;
        margin: 40px auto 0;
        justify-content: flex-start;
    }
    .blocks {
        justify-content: flex-start;
        margin-bottom: 20px;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
    }
    .block {
        width: 100%;
    }
    .container {
        width: 100%;
    }
    .block {
        width: 100%;
        margin-bottom: 10px;
    }
    .icons {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
    }
    .bottom {
        margin-left: 0;
    }
}

.form__group {
    position: relative;
    padding: 20px 12px 16px;
    border: 1px solid #D4DCE3;
    border-radius: 8px;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 14px;
    color: #000000;
    padding: 10px 0;
    background: transparent;
    resize: none;
    position: relative;
}

.form__field::placeholder {
    color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
    font-size: 14px;
    cursor: text;
    top: 20px;
}
.textarea__label,
.form__field:focus ~ .form__label {
    position: absolute;
    top: 12px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #9b9b9b;
}

.form__field:focus ~ .form__label {
    color: #8697A5;
}

.main-page {
    position: relative;
}
.main-page-background {
    width: 100%;
    height: calc(100vh + 160px);
}

.main-page-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 45px;
}

.content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 140px;
}

.block {
    width: 280px;
}

.blockTitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin: 16px 0 8px;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
}

@media screen and (max-width: 1450px) {
    .block {
        width: 200px;
    }

    .main-page-background {
        height: calc(100vh + 160px);
    }

    .blockTitle {
        font-size: 16px;
    }

    .block {
        width: 23%;
    }

}

@media screen and (max-width: 1300px) {
    .main-page-background {
        height: 1000px;
    }
    .title {
        font-size: 24px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 730px) {
    .main-page-background {
        height: 220px;
    }
    .title {
        font-size: 24px;
        margin-top: 20px;
    }

    .content {
        flex-direction: column;
    }

    .block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
    }
}
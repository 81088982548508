.content {
    background: #FFFFFF;
    box-shadow: 0 8px 16px rgba(106, 103, 135, 0.16);
    border-radius: 8px;
    width: 200px;
    padding-top: 4px;
    box-sizing: border-box;
    padding-bottom: 12px;
}

.title {
    box-sizing: border-box;
    width: 192px;
    background: #53AFCC;
    border-radius: 4px;
    margin: 0 auto;
    padding: 4px 8px;
    color: white;
    font-size: 10px;
    line-height: 14px;
    font-weight: bold;
}

.textTitle {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    box-sizing: border-box;
    margin-top: 8px;
}

.place {
    display: flex;
    justify-content: space-between;
}

.city {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.price {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #4230FF;
}

.button {
    margin-top: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #4230FF;
}

.disabled {
    display: none;
}
.container {
    position: fixed;
    z-index: 999;
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
}

.modal {
    margin-top: 50px;
    background: white;
    min-width: 480px;
    border-radius: 16px;
    position: relative;
    min-height: 100px;
    box-sizing: border-box;
}

.cansel {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: .3s;
}

.cansel:hover {
    opacity: .6;
}
* {
    box-sizing: border-box;
}

.form__field {
    position: relative;
    width: 100%;
    color: #000000;
    background: transparent;
    resize: none;
}

.form__list,
.form__list ul {
    position: absolute;
    z-index: 800;
    width: 100%;
    padding: 0;
    border: 1px solid #D4DCE3;
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    background: #ffffff;
    list-style-type: none;
}

.form__defaultvariant {
    padding: 8px;
    color: #8697A5;
    background: #F5F6F8;
}

.form__variant {
    padding: 8px;
    background: #F5F6F8;
}

.form__variant:hover {
    color: #ffffff;
    background: /*transparent*/ #4230ff;
}

.form__popup {
    position: fixed;
    z-index: 800;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.66);
}

.form__area {
    position: relative;
    border-radius: 8px;
    background-color: #FFFFFF;

    width: 800px;
    left: 0;
    right: 0;
}

.title {
    font-weight: bold;
    color: #4230ff;
}

.input {
    border: 1px solid #D4DCE3;
    border-radius: 8px;
    cursor: pointer;
}

.detail__fields {
    width: 100%;
    background: transparent;
    padding: 0;
}

.detail__fields td {
    vertical-align: top;
}

.divider {
    width: 100%;
    height: 1px;
    background: #D4DCE3;
    margin-bottom: 8px /*20px*/;
}

.buttonsareaWrapper {
    background: white;
    display: inline-block;
}
.buttonsareaWrapper button {
    vertical-align: middle;
}


@media screen and (max-height: 969px) {
    .form__field {
        padding: 0 /*12px 0*/;
        border: 0;
        outline: 0;
        font-size: 12px;
    }

    .form__listdown {
        top: 32px;
        border-radius: 0 0 8px 8px;
    }

    .form__listup {
        bottom: 0px;
        border-radius: 8px 8px 0 0;
    }

    .form__area {
        top: 105px;
        margin: auto;
        padding: 16px;
        border: 1px solid #D4DCE3;
    }

    .title {
        font-size: 18px;
        line-height: 1.5;
        width: 1280px;
        margin-bottom: 5px;
    }

    .detail__fields {
        border-spacing: 4px;
    }
}

@media screen and (min-height: 970px) and (max-height: 1332px) {
    .form__field {
        padding: 0/*12px 0*/;
        border: 0;
        outline: 0;
        font-size: 14px;
    }

    .form__listdown {
        top: 36px;
        border-radius: 0 0 8px 8px;
    }

    .form__listup {
        bottom: 2px;
        border-radius: 8px 8px 0 0;
    }

    .form__area {
        top: 140px;
        margin: auto;
        padding: 16px;
        border: 1px solid #D4DCE3;
    }

    .title {
        font-size: 24px;
        line-height: 1.5;
        width: 1280px;
        margin-bottom: 10px;
    }

    .detail__fields {
        border-spacing: 4px 12px 4px;
    }
}

@media screen and (min-height: 1333px) {
    .form__field {
        padding: 0/*12px 0*/;
        border: 0;
        outline: 0;
        font-size: 16px;
    }

    .form__listdown {
        top: 40px;
        border-radius: 0 0 8px 8px;
    }

    .form__listup {
        bottom: 4px;
        border-radius: 8px 8px 0 0;
    }

    .form__area {
        top: 180px;
        margin: auto;
        padding: 16px;
        border: 2px solid #D4DCE3;
    }

    .title {
        font-size: 36px;
        line-height: 1.5;
        width: 1280px;
        margin-bottom: 20px;
    }

    .detail__fields {
        border-spacing: 4px 12px 4px;
    }
}

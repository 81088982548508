.button {
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 56px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    transition: .3s;
}

.button:hover {
    opacity: .8;
}

.noFill {
    background: transparent;
    color: #4230FF;
}

.bordered {
    border: 1px solid #4230FF;
    background: transparent;
    color: #4230FF;
}

.indented {
    margin-left: 4px;
    margin-right: 4px;
}

.disabled {
    opacity: 0.3;
}

.disabled:hover {
    opacity: 0.3;
}

@media screen and (max-width: 1450px) {
    .button {
        font-size: 16px;
    }
}
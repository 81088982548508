.content {
    background: #F5F5F5;
}
.booking {
    position: relative;
    min-height: calc(100vh - 132px);
}

@media screen and (max-width: 1250px) {
    .map {
        height: 700px;
        top: 119px;
    }
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gm-style .gm-style-cc span, .gm-style .gm-style-cc a, .gm-style .gm-style-mtc div {
  display: none !important;
}


.react-tel-input .form-control {
  width: 100% !important;
  height: 60px !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  padding-top:8px !important;
}

.react-tel-input .selected-flag {
  padding-top:8px !important;
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown.open,
.react-tel-input .flag-dropdown.open .selected-flag {
  padding-top:8px !important;
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input .special-label {
  position:absolute;
  z-index:1;
  top:8px;
  left:10.5px;
  display:block;
  background:white;
  color: #8697A5;
  font-size:10.5px;
  font-family: 'Montserrat', sans-serif !important;
}
.react-tel-input .special-label span {
  color: red;
}


.react-time-picker .react-time-picker__wrapper {
  border: none;
  height: 56px;
  padding-left: 20px;
}

.react-time-picker .react-time-picker__wrapper input {
  border: none;
  outline: none;
}

.react-datepicker {
  background: #FFFFFF !important;
  border: 1px solid #D4DCE3 !important;
  border-radius: 8px !important;
  overflow: hidden;
}

.react-datepicker__header {
  background-color: #4230FF !important;
  border-bottom: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 8px;
  padding-bottom: 4px;
  position: relative;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
  background-color: #4230FF !important;
  color: #FFFFFF !important;
}

.react-datepicker__day, .react-datepicker__time-name {
  font-family: 'Montserrat', sans-serif !important;
  /*border: 1px solid #D4DCE3 !important;
  border-radius: 0 !important;*/
  margin: 0 !important;
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__saturday {
  color: #4230FF;
}
.react-datepicker__sunday {
  color: #FF0000;
}

.react-datepicker__day-name {
  color: #FFFFFF !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 10px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #FFFFFF !important;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal !important;
  font-weight: 1000 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none !important;
  border: none !important;
}

.wrapper {
    width: 1280px;
    margin: 40px auto 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
}

.form {
    margin-bottom: 16px;
}
.firstInput {
    border: 1px solid #D4DCE3;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
}
.secondInput {
    border: 1px solid #D4DCE3;
    border-top: none;
    border-radius: 0 0 8px 8px;
    cursor: pointer;
}

.left {
    width: 440px;
}

.title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
}

.text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 36px;
}

.titleBlock {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
}

.pricesBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cost {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4230FF;
}

.cost b {
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
}

.button {
    width: 180px;
    background: #4230FF;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    height: 56px;
    padding: 0 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    transition: .3s;
}

.right {
    width: 760px;
}

.map {
    width: 760px;
    height: 320px;
    margin-bottom: 16px;
}

.priceList {
    display: flex;
    flex-wrap: wrap;
}

.placeBlock {
    width: 250px;
    display: flex;
    margin-bottom: 16px;
}

.point {
    min-width: 6px;
    min-height: 6px;
    width: 6px;
    height: 6px;
    background: black;
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 8px;
}

.placeTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}
.placeText {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.placeBlock:nth-child(1) .point{
    background: #4230FF;
}
.placeBlock:nth-child(1) .placeTitle{
    color: #4230FF;
}

.placeBlock:nth-child(2) .point{
    background: #FF6B00;
}
.placeBlock:nth-child(2) .placeTitle{
    color: #FF6B00;
}

.placeBlock:nth-child(3) .point{
    background: #CE4CC9;
}
.placeBlock:nth-child(3) .placeTitle{
    color: #CE4CC9;
}

.placeBlock:nth-child(4) .point{
    background: #B0CF36;
}
.placeBlock:nth-child(4) .placeTitle{
    color: #B0CF36;
}

.placeBlock:nth-child(5) .point{
    background: #53AFCC;
}
.placeBlock:nth-child(5) .placeTitle{
    color: #53AFCC;
}

@media screen and (max-width: 1450px) {
    .wrapper {
        width: 1150px;
    }

    .left {
        width: 380px;
    }

    .right {
        width: 540px;
    }

    .map {
        width: 540px;
    }

    .placeBlock {
        width: 180px;
    }
}

@media screen and (max-width: 1300px) {
    .wrapper {
        width: 720px;
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .right {
        margin-top: 16px;
        width: 100%;
    }

    .map {
        width: 100%;
    }

    .placeBlock {
        width: 33%;
    }
}

@media screen and (max-width: 730px) {
    .wrapper {
        width: 288px;
    }

    .priceList {
        justify-content: space-between;
    }
    .placeBlock {
        width: 50%;
    }
    .pricesBlock {
        flex-direction: column;
        align-items: flex-start;
    }
}
.firstBlock {
    background: #F5F5F5;
    padding: 80px 0 120px;
}

.firstBlockWrapper {
    display: flex;
    justify-content: space-between;
}

.firstTitle {
    width: 315px;
    font-weight: bold;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 45px;
    transform: skewX(175deg);
}

.firstTitle span {
    background: #4230FF;
}

.firstText {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 640px;
}

.secondBlock {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
}

.secondLeft {
    position: relative;
}

.secondTitle {
    position: absolute;
    top: 40px;
    left: 8px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    transform: skewX(175deg);
}

.secondTitle span {
    background: #4230FF;
}

.secondRight {
    width: 640px;
}

.secondRightText {
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.grayBlock {
    background: #F5F5F5;
    border-radius: 24px;
    box-sizing: border-box;
    padding: 48px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
}

.grayBlockTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 36px;
}

.button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #4230FF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px 0;
    width: 188px;
    text-decoration: none;
    transition: .3s;
}

.button:hover {
    opacity: .6;
}

.thirdBlock {
    background-image: url("../../assets/about/thirdBg.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 120px 0 160px;
}

.thirdBlockTitle {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    margin-bottom: 64px;
}

.thirdBlockContent {
    display: flex;
    justify-content: space-between;
}

.thirdBlockTextFirst {
    width: 560px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */


    /* Primary / White */

    color: #FFFFFF;
}

.thirdBlockTextFirst div {
    margin-bottom: 20px;
}

.thirdBlockTextSecond {
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    width: 480px;
}

.thirdBlockTextSecond div {
    margin-bottom: 20px;
}

.fourBlock {
    position: relative;
    height: 580px;
    box-sizing: border-box;
}

.fourBlockTitle {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
    transform: skewX(175deg);
    margin: 0 0 64px;
    padding-top: 160px;
}

.fourBlockTitle span {
    background: #4230FF;
}

.fourBlockText {
    width: 480px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
}

.fourBlockLink {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #4230FF;
    display: block;
}

.fourBlockImage {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 100%;
}

.fourBlockImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fiveBlock {
    background: #F5F5F5;
    padding: 120px 0 80px;
}

.fiveBlockTitle {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 64px;
}

.fiveBlockWrapper {
    display: flex;
    justify-content: space-between;
}

.fiveBlockLeft {
    width: 600px;
}

.fiveBlockTextLight {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.fiveBlockTextBold {
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
}

.fiveBlockRight {
    background: rgba(66,48,255, 0.04);
    display: flex;
    height: 88px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px;
    align-items: center;
}

.fiveBlockTextBlue {
    width: 305px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4230FF;
    padding-left: 16px;
    box-sizing: border-box;
}

.sixBlockRight {
    width: 760px;
}

.map {
    width: 760px;
    height: 320px;
    margin-bottom: 16px;
}

.priceList {
    display: flex;
    flex-wrap: wrap;
}

.placeBlock {
    width: 250px;
    display: flex;
    margin-bottom: 16px;
}

.point {
    min-width: 6px;
    min-height: 6px;
    width: 6px;
    height: 6px;
    background: black;
    border-radius: 50%;
    margin-top: 6px;
    margin-right: 8px;
}

.placeTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px
}
.placeText {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.placeBlock:nth-child(1) .point{
    background: #4230FF;
}
.placeBlock:nth-child(1) .placeTitle{
    color: #4230FF;
}

.placeBlock:nth-child(2) .point{
    background: #FF6B00;
}
.placeBlock:nth-child(2) .placeTitle{
    color: #FF6B00;
}

.placeBlock:nth-child(3) .point{
    background: #CE4CC9;
}
.placeBlock:nth-child(3) .placeTitle{
    color: #CE4CC9;
}

.placeBlock:nth-child(4) .point{
    background: #B0CF36;
}
.placeBlock:nth-child(4) .placeTitle{
    color: #B0CF36;
}

.placeBlock:nth-child(5) .point{
    background: #53AFCC;
}
.placeBlock:nth-child(5) .placeTitle{
    color: #53AFCC;
}

.sixBlock {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 64px;
}
.sixButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    background: #4230FF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px 0;
    width: 180px;
    text-decoration: none;
    transition: .3s;
    display: block;
}

.sixButton:hover {
    opacity: .6;
}

.sixBlockLeft {
    width: 420px;
}

.sixBlockText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
}

.sevenBlock {
    background: #F5F5F5;
    padding: 120px 0 180px;
}

.sevenBlockTitle {
    font-weight: bold;
    font-size: 32px;
    color: #FFFFFF;
    line-height: 45px;
    transform: skewX(175deg);
    margin-bottom: 68px;
}

.sevenBlockTitle span {
    background: #4230FF;
}

.sevenBlockContent {
    display: flex;
    justify-content: space-between;
}

.sevenBlockItem {
    width: 270px;
}

.sevenBlockContentTitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 8px 0 28px;
}

.sevenBlockContentText {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.eighthBlock {
    margin-top: -60px;
}

.ninthBlock {
    display: flex;
    justify-content: space-between;
    margin-top: 120px;
}

.ninthBlockLeft {
    width: 560px;
}

.ninthBlockLeftTextBlock {
    margin-bottom: 80px;
}

.ninthBlockLeftText {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.ninthRight {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 500px;
}

.ninthRightBlock {
    width: 220px;
}

.ninthBlockRightText {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
}

@media screen and (max-width: 1450px) {
    .sixBlockRight, .map {
        width: 600px;
    }
    .placeBlock {
        width: 200px;
    }
}

@media screen and (max-width: 1300px) {
    .firstBlockWrapper, .secondBlock, .thirdBlockContent, .fiveBlockWrapper, .sixBlock, .sevenBlockContent, .ninthBlock {
        flex-direction: column;
    }
    .firstTitle {
        margin-bottom: 30px;
    }
    .firstTitle,
    .firstText,
    .secondLeft,
    .secondRight,
    .grayBlockTitle,
    .thirdBlockTextFirst,
    .thirdBlockTextSecond,
    .fourBlockText,
    .fiveBlockLeft,
    .fiveBlockRight,
    .sixBlockLeft,
    .sixBlockRight,
    .map,
    .sevenBlockItem,
    .ninthBlockLeft,
    .ninthRight {
        width: 100%;
    }
    .secondLeft img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .secondTitle {
        font-size: 24px;
        top: 10px;
    }

    .grayBlockTitle {
        font-size: 18px;
        text-align: center;
    }

    .thirdBlock {
        padding: 30px 0;
    }

    .fourBlockImage {
        display: none;
    }

    .fiveBlockRight {
        padding: 10px;
        margin-top: 20px;
    }
    .sixBlockRight {
        margin-top: 20px;
    }
    .sevenBlockItem {
        margin-bottom: 30px;
    }
    .ninthRightBlock {
        text-align: center;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 730px) {
    .placeBlock, .ninthRightBlock {
        width: 100%;
    }
}